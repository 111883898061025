import React from 'react';
const PropertyCheck = ({label, defaultChecked}) => {
  return (
    <>
        <div className="inline-flex items-center float-left px-2 py-2 gap-x-2">
          <input
            id="comments"
            aria-describedby="comments-description"
            name={label}
            type="checkbox"
            className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary"
          /> <label for={label}>{label}</label>
        </div>
    </>
  );
};

export default PropertyCheck;