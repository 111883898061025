import React from "react"
import Layout from "../components/layout-pdf"
import Seo from "../components/seo"
import PropertyCheck from "../components/PropertyCheck"
import PDF from "../components/pdfTemplate"

const Template = () => {
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
    return (

      <Layout>
      <Seo title="Home" />
      <div id="id1"></div>
      <PDF
        title="EBS"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1020839615.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA EBS012022"
        type="EBS"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id2"></div>
      <PDF
        title="Come & Get"
        data={"https://hello.archermalmo.com/wc/json/Sheet_875361674.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA COME012022"
        type="Come & Get"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id3"></div>
      <PDF
        title="Ticketed Drawing"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1252062974.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA TD12022"
        type="Ticketed Drawing"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id4"></div>
      <PDF
        title="Tournament"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1947685971.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA TOURN012022"
        type="Tournament"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id5"></div>
      <PDF
        title="Sweepstakes"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1531557913.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA SWEEPS012022"
        type="Sweepstakes"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id6"></div>
      <PDF
        title="Summary"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1397928695.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA SUMMARY012022"
        type="Summary"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id7"></div>
      <PDF
        title="Hot Seats"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1187758004.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA HS012022"
        type="Hot Seats"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id8"></div>
      <PDF
        title="Electronic Kiosk"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1540004449.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA EKG012022"
        type="Electronic Kiosk"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />
      <div id="id9"></div>
      <PDF
        title="Electronic Drawing"
        data={"https://hello.archermalmo.com/wc/json/Sheet_1859327813.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA ED012022"
        type="Electronic Drawing"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }

      />
      <div id="id10"></div>
      <PDF
        title="Earn & Get"
        data={"https://hello.archermalmo.com/wc/json/Sheet_78409997.json?rand=" + getRandomInt(100)}
        promoTemplate="PCIGA EARN012022"
        type="Earn & Get"
        objective="Gaming Revenue"
        property={
          <>
            <PropertyCheck label="WCM" defaultChecked={false}/>
            <PropertyCheck label="WCW" defaultChecked={false}/>
            <PropertyCheck label="WCA" defaultChecked={false}/>
          </>
        }
      />

      </Layout>
    )
};
export default Template





